import { Address } from "./AddressModel";

export class StaffDetails {
  constructor (){
    this.address = new Address();
  }

  id: number;
  first_name: string;
  last_name: string;
  position: string;
  staff_id : string;
  phone: string;
  email: string;
  address : Address;
  birth_date: Date;
  start_date: Date;
  memo : string;
  is_active : boolean;
  user_id : number;
}