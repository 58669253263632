<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-header p-8 pb-0">
        <h2 v-if="staffId===0">Create Staff</h2>
        <h2 v-else>Staff details</h2>
    </div>
    <div class="card-body pt-9 pb-0" v-if="staffDetail.address">
        <el-form
            id="modal_agent_detail_form"
            class="form"
            :model="staffDetail"
            ref="formRef"
        >
            <div class="modal-body py-lg-10 px-lg-10">
                <div class="row mb-6">
                    <label for="staff_type" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span>Type</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-select
                            class="form-select-solid w-100"
                            placeholder="Select staff type"
                            v-model="staffDetail.type">
                            <el-option v-for="type in types" 
                                :label="type"   
                                :value="type" 
                                :key="type">
                                {{type}}
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="row mb-6" v-if="staffId!=0">
                    <label for="staff_type" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span>Staff ID</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <input
                            type="text"
                            name="fname"
                            class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            disabled
                            v-model="staffDetail.staff_id"
                        />
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="staffname" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Name</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <div class="row">
                        <div class="col-lg-6 fv-row">
                            <el-form-item prop="first_name">
                                <input
                                    type="text"
                                    name="fname"
                                    class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                    placeholder="First name"
                                    v-model="staffDetail.first_name"
                                />
                            </el-form-item>
                        </div>
                        <div class="col-lg-6 fv-row">
                            <el-form-item prop="last_name">
                                <input
                                    type="text"
                                    name="lname"
                                    class="form-control form-control-lg form-control-solid"
                                    placeholder="Last name"
                                    v-model="staffDetail.last_name"
                                />
                            </el-form-item>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="staffPosition" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span>Position</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <div class="row">
                            <div class="col-lg-6 fv-row">
                                <el-form-item prop="position">
                                    <input
                                        type="text"
                                        name="fname"
                                        class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                        placeholder="Position"
                                        v-model="staffDetail.position"
                                    />
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="agent_phone" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span>Phone</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="phone">
                            <input 
                                type="tel" 
                                class="form-control form-control-solid" 
                                name="phone" 
                                placeholder="Phone"
                                v-model="staffDetail.phone"
                                @input="acceptPhoneNumber" />
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label for="agent_email" class="col-lg-3 col-form-label fw-bold fs-6">
                        <span class="required">Email</span>
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-form-item prop="email">
                            <input 
                                type="email" 
                                name="email" 
                                class="form-control form-control-solid" 
                                placeholder="Email" 
                                v-model="staffDetail.email"/>
                        </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label class="col-lg-3 col-form-label fw-bold fs-6">
                    Date of Birth
                    </label>
                    <div class="col-lg-9 fv-row">
                    <el-form-item prop="birth_date">
                        <el-date-picker
                            v-model="staffDetail.birth_date"
                            type="date"
                            placeholder="Date of birth"
                            format="MM/DD/YYYY"
                        />
                    </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label class="col-lg-3 col-form-label fw-bold fs-6">
                        Address
                    </label>

                    <div class="col-lg-9 fv-row">
                        <div class="row">
                            <div class="col-lg-12 fv-row">
                            <el-form-item prop="address1">
                            <input
                                type="text"
                                name="address1"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Address1"
                                v-model="staffDetail.address.address1"
                            />
                            </el-form-item>
                            </div>
                            <div class="col-lg-12 fv-row">
                            <el-form-item prop="address2">
                            <input
                                type="text"
                                name="address2"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Address2"
                                style="margin-top:5px;margin-bottom:5px;"
                                v-model="staffDetail.address.address2"
                            />
                            </el-form-item>
                            </div>
                            <div class="col-lg-4 fv-row">
                            <el-form-item prop="city">
                                <input
                                type="text"
                                name="city"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                placeholder="City"
                                v-model="staffDetail.address.city"
                                />
                            </el-form-item>
                            </div>
                            <div class="col-lg-4 fv-row">
                            <el-form-item prop="state">
                                <input
                                type="text"
                                name="State"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                placeholder="State"
                                v-model="staffDetail.address.state"
                                />
                            </el-form-item>
                            </div>
                            <div class="col-lg-4 fv-row">
                            <el-form-item prop="zipcode">
                                <input
                                type="text"
                                name="zipcode"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                placeholder="Zip Code"
                                v-model="staffDetail.address.zipcode"
                                />
                            </el-form-item>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-6">
                    <label class="col-lg-3 col-form-label fw-bold fs-6">
                    Start Date
                    </label>

                    <div class="col-lg-9 fv-row">
                    <el-form-item prop="start_date">
                        <el-date-picker
                            v-model="staffDetail.start_date"
                            type="date"
                            placeholder="Start Date"
                        />
                    </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label class="col-lg-3 col-form-label fw-bold fs-6">
                        Remark
                    </label>

                    <div class="col-lg-9 fv-row">
                    <el-form-item prop="memo">
                        <el-input
                            v-model="staffDetail.memo"
                            type="textarea"
                            rows="3"
                            placeholder="Remark"
                            style="width:100%"
                        />
                    </el-form-item>
                    </div>
                </div>
                <div class="row mb-6">
                    <label class="col-lg-3 col-form-label fw-bold fs-6">
                        Files
                    </label>
                    <div class="col-lg-9 fv-row">
                        <el-table class="down" v-if="fileList.length > 0" :data="fileList" border stripe style="width: 100%;margin-bottom: 20px;">
                            <el-table-column prop="file_name" label="File name"></el-table-column>
                            <el-table-column prop="create_date" label="Upload time">
                            <template v-slot="scope">
                                {{moment(scope.row.create_date).format('MM/DD/YYYY')}} 
                            </template>
                            </el-table-column>
                            <el-table-column width="150px" label="operation">
                                <template v-slot="scope">
                                    <el-button size="small" type="text">
                                        <a @click="downloadFile(scope.row.id,scope.row.file_name)">download</a>
                                    </el-button>
                                    <el-button size="small" type="text" @click="deleteSavedFile(scope.row)">delete</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-upload
                            ref="upload"
                            class="upload-demo"
                            :on-change="addFileData"
                            :on-remove="removeFileData"
                            action="#"
                            :limit="3"
                            :on-exceed="handleExceed"
                            :auto-upload="false"
                        >
                            <template #trigger>
                                <el-button type="primary">select file</el-button>
                            </template>
                            <el-input
                                placeholder="Enter file description"
                                class="col-md-8"
                                style="margin-left:10px;margin-right:10px;display:none;"/>
                            <template #tip>
                                <div class="el-upload__tip" style="color: red">
                                    limit 3 Files, new file will cover the old file
                                </div>
                            </template>
                        </el-upload>
                    </div>
                </div>
                <div class="row mb-6">
                    <label class="col-lg-3 col-form-label fw-bold fs-6">
                        Active
                    </label>
                    <div class="col-lg-9 d-flex align-items-center">
                        <div class="form-check form-check-solid form-switch fv-row">
                            <el-form-item prop="isActive">
                                <input class="form-check-input w-45px h-30px" name="isActive" type="checkbox" id="isActive" v-model="staffDetail.is_active" />
                                <label class="form-check-label" for="isActive"></label>
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="modal-footer flex-center">
            <a
                @click="backToList"
                class="btn btn-lg btn-light-primary fw-bolder"
            >
                Back to list
            </a>
            <button
              type="button"
              id="modal_agent_detail_submit"
              class="btn btn-primary"
              ref="submitButton"
              @click="submit"
            >
              <span class="indicator-label"> Save </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <button
              type="button"
              class="btn btn-danger"
              ref="sdeleteButton"
              @click="deleteStaff"
              v-if="staffId!=0"
            >
              <span class="indicator-label"> Delete </span>
            </button>
        </div>
      </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onBeforeMount, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Address } from "@/types/AddressModel";
import { StaffDetails } from "@/types/StaffModel";
import CommonService from "@/core/services/CommonService";
import { ElMessageBox } from "element-plus";
import moment from "moment";

export default defineComponent({
    name: "agent-detail-modal",
    components: {
    },
    setup() {
        const route = useRoute();
        const staffId = +route.params.id; 
        const formRef = ref<null | HTMLFormElement>(null);
        const submitButton = ref<HTMLElement | null>(null);
        const staffDetail = reactive({} as any);
        const types = ref([]);
        const fileList = ref([]);
        const uploadFileList = ref([] as any);

        const getFiles = () =>{
            if(staffId != 0){
                return new Promise((resolve, reject) => {           
                    ApiService.setHeader(); 
                    ApiService.get("file/staff/"+staffId)
                        .then(({ data }) => {
                            fileList.value = data.data;
                            resolve(data.data);
                        })
                        .catch(({ response }) => {
                            reject(new Error ('getStaffTypes => '+response.data.errors));
                        });
                });
            }
        }
        const getStaffTypes = async() =>{
            await new Promise((resolve, reject) => {           
                ApiService.setHeader(); 
                ApiService.get("staff/types")
                    .then(({ data }) => {
                        types.value = data.data;
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(new Error ('getStaffTypes => '+response.data.errors));
                    });
            });
        }

        const getStaff = () => {
            if(staffId == 0) {
                Object.assign(staffDetail,new StaffDetails());
            }
            else {
                return new Promise((resolve, reject) => { 
                    ApiService.setHeader();           
                    ApiService.get("staff/"+staffId)
                        .then(({ data }) => {
                            if(data.data){
                                if(!data.data.address){
                                    data.data.address = new Address();
                                }
                                Object.assign(staffDetail,data.data);
                            }

                            resolve(data.data);
                        })
                        .catch(({ response }) => {
                            reject(new Error ('getStaff error => '+response.data.errors));
                        });
                });
            }
        }

        const submit = () => {
            submitButton.value?.setAttribute("data-kt-indicator", "on");

            var formData = new FormData();
            uploadFileList.value.map(file=>{
                formData.append('files', file.raw, file.name);
            });
            formData.append("document", JSON.stringify(staffDetail));
            ApiService.setHeader();
            ApiService.setFormDataHeader();

            if(staffId === 0){
                ApiService.post("staff", formData as any)
                    .then(() => {
                        Swal.fire({
                        text: "Staff information has been successfully saved!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(() => {
                            router.push({ name: "staff"});
                        });
                    })
                    .catch(({ response }) => {
                        console.log(response);
                        Swal.fire({
                        text: "Failed to create the new staff",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        });
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
            else
            {
                ApiService.update("staff", staffId, formData as any)
                    .then(() => {
                        Swal.fire({
                            text: "Staff is updated successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                router.push({ name: "staff"});
                            });
                    })
                    .catch(({ response }) => {
                        console.log(response);
                        Swal.fire({
                        text: "Failed to update the staff",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        });
                    });
            }
        };

        const acceptPhoneNumber = () => {
            staffDetail.phone = CommonService.getPhoneNumber(staffDetail.phone);
        }

        const addFileData = (file) => {
            uploadFileList.value.push(file);
            return false;
        }

        const removeFileData = (file) =>{
            uploadFileList.value = uploadFileList.value.filter(x=>x.uid != file.uid);
        }

        const downloadFile = (id,name) => {
            ApiService.setHeader();
            ApiService.get("file/url/"+id)
            .then(({ data }) => {
            var a = document.createElement('a');
            var event = new MouseEvent('click');
            a.download = name;
            a.href = data.url;
            a.dispatchEvent(event);
            })
            .catch((error)=>{
                console.log('downloadFile error => '+error);
            })
        }

        const deleteSavedFile = (row) =>{
            ElMessageBox.confirm('Are you sure to delete this file?')
                .then(() => {
                ApiService.setHeader();
                ApiService.delete("file/"+row.id)
                .then(({ data }) => {
                    if(data.statusCode != 200){
                    Swal.fire({
                            text: "Deleting file error",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Please try again",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                        });
                    }
                    else{
                    Swal.fire({
                            text: "Deleted successfully",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            getFiles();
                        });
                    }
                })
                .catch((error)=>{
                    console.log('deleteSavedFile error => '+error);
                });
            });
        }

        const deleteStaff = () =>{
            ElMessageBox.confirm('Are you sure to delete this staff?')
                .then(() => {
                    ApiService.delete("staff/"+staffId)
                        .then(() => {
                            Swal.fire({
                                text: "Staff is deleted successfully",
                                icon: "success",
                                buttonsStyling: false,  
                                confirmButtonText: "Ok, got it!",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                })
                                .then(() => {
                                    router.push({ name: "staff"});
                                });
                        })
                        .catch(({ response }) => {
                            console.log(response);
                            Swal.fire({
                            text: "Failed to delete the staff",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            });
                        })
                        .finally(() =>{
                            submitButton.value?.removeAttribute("data-kt-indicator");
                        });
                });
        }

        const backToList = () =>{
            router.go(-1);
        }

        onBeforeMount(() => {
            getStaffTypes();
            getStaff();
            getFiles();
        })

        onMounted(() => {
            setCurrentPageBreadcrumbs(staffId != 0 ? "Staff Detail": "Create staff", ["Management", "Faculty/Staff"]);
        });

        return {
            submit,
            formRef,
            staffDetail,
            staffId,
            types,
            acceptPhoneNumber,
            addFileData,
            fileList,
            deleteSavedFile,
            downloadFile,
            moment,
            removeFileData,
            deleteStaff,
            backToList
        };
    },
});
</script>

<style scoped>
*>>> .el-table td, .el-table th {
    padding:5px 0;
}
</style>