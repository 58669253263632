
import { defineComponent, ref, reactive, onBeforeMount, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRoute } from 'vue-router'
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Address } from "@/types/AddressModel";
import { StaffDetails } from "@/types/StaffModel";
import CommonService from "@/core/services/CommonService";
import { ElMessageBox } from "element-plus";
import moment from "moment";

export default defineComponent({
    name: "agent-detail-modal",
    components: {
    },
    setup() {
        const route = useRoute();
        const staffId = +route.params.id; 
        const formRef = ref<null | HTMLFormElement>(null);
        const submitButton = ref<HTMLElement | null>(null);
        const staffDetail = reactive({} as any);
        const types = ref([]);
        const fileList = ref([]);
        const uploadFileList = ref([] as any);

        const getFiles = () =>{
            if(staffId != 0){
                return new Promise((resolve, reject) => {           
                    ApiService.setHeader(); 
                    ApiService.get("file/staff/"+staffId)
                        .then(({ data }) => {
                            fileList.value = data.data;
                            resolve(data.data);
                        })
                        .catch(({ response }) => {
                            reject(new Error ('getStaffTypes => '+response.data.errors));
                        });
                });
            }
        }
        const getStaffTypes = async() =>{
            await new Promise((resolve, reject) => {           
                ApiService.setHeader(); 
                ApiService.get("staff/types")
                    .then(({ data }) => {
                        types.value = data.data;
                        resolve(data.data);
                    })
                    .catch(({ response }) => {
                        reject(new Error ('getStaffTypes => '+response.data.errors));
                    });
            });
        }

        const getStaff = () => {
            if(staffId == 0) {
                Object.assign(staffDetail,new StaffDetails());
            }
            else {
                return new Promise((resolve, reject) => { 
                    ApiService.setHeader();           
                    ApiService.get("staff/"+staffId)
                        .then(({ data }) => {
                            if(data.data){
                                if(!data.data.address){
                                    data.data.address = new Address();
                                }
                                Object.assign(staffDetail,data.data);
                            }

                            resolve(data.data);
                        })
                        .catch(({ response }) => {
                            reject(new Error ('getStaff error => '+response.data.errors));
                        });
                });
            }
        }

        const submit = () => {
            submitButton.value?.setAttribute("data-kt-indicator", "on");

            var formData = new FormData();
            uploadFileList.value.map(file=>{
                formData.append('files', file.raw, file.name);
            });
            formData.append("document", JSON.stringify(staffDetail));
            ApiService.setHeader();
            ApiService.setFormDataHeader();

            if(staffId === 0){
                ApiService.post("staff", formData as any)
                    .then(() => {
                        Swal.fire({
                        text: "Staff information has been successfully saved!",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(() => {
                            router.push({ name: "staff"});
                        });
                    })
                    .catch(({ response }) => {
                        console.log(response);
                        Swal.fire({
                        text: "Failed to create the new staff",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        });
                    })
                    .finally(() =>{
                        submitButton.value?.removeAttribute("data-kt-indicator");
                    });
            }
            else
            {
                ApiService.update("staff", staffId, formData as any)
                    .then(() => {
                        Swal.fire({
                            text: "Staff is updated successfully",
                            icon: "success",
                            buttonsStyling: false,  
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            })
                            .then(() => {
                                router.push({ name: "staff"});
                            });
                    })
                    .catch(({ response }) => {
                        console.log(response);
                        Swal.fire({
                        text: "Failed to update the staff",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        });
                    });
            }
        };

        const acceptPhoneNumber = () => {
            staffDetail.phone = CommonService.getPhoneNumber(staffDetail.phone);
        }

        const addFileData = (file) => {
            uploadFileList.value.push(file);
            return false;
        }

        const removeFileData = (file) =>{
            uploadFileList.value = uploadFileList.value.filter(x=>x.uid != file.uid);
        }

        const downloadFile = (id,name) => {
            ApiService.setHeader();
            ApiService.get("file/url/"+id)
            .then(({ data }) => {
            var a = document.createElement('a');
            var event = new MouseEvent('click');
            a.download = name;
            a.href = data.url;
            a.dispatchEvent(event);
            })
            .catch((error)=>{
                console.log('downloadFile error => '+error);
            })
        }

        const deleteSavedFile = (row) =>{
            ElMessageBox.confirm('Are you sure to delete this file?')
                .then(() => {
                ApiService.setHeader();
                ApiService.delete("file/"+row.id)
                .then(({ data }) => {
                    if(data.statusCode != 200){
                    Swal.fire({
                            text: "Deleting file error",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Please try again",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                        });
                    }
                    else{
                    Swal.fire({
                            text: "Deleted successfully",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            getFiles();
                        });
                    }
                })
                .catch((error)=>{
                    console.log('deleteSavedFile error => '+error);
                });
            });
        }

        const deleteStaff = () =>{
            ElMessageBox.confirm('Are you sure to delete this staff?')
                .then(() => {
                    ApiService.delete("staff/"+staffId)
                        .then(() => {
                            Swal.fire({
                                text: "Staff is deleted successfully",
                                icon: "success",
                                buttonsStyling: false,  
                                confirmButtonText: "Ok, got it!",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                })
                                .then(() => {
                                    router.push({ name: "staff"});
                                });
                        })
                        .catch(({ response }) => {
                            console.log(response);
                            Swal.fire({
                            text: "Failed to delete the staff",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Ok, got it!",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            });
                        })
                        .finally(() =>{
                            submitButton.value?.removeAttribute("data-kt-indicator");
                        });
                });
        }

        const backToList = () =>{
            router.go(-1);
        }

        onBeforeMount(() => {
            getStaffTypes();
            getStaff();
            getFiles();
        })

        onMounted(() => {
            setCurrentPageBreadcrumbs(staffId != 0 ? "Staff Detail": "Create staff", ["Management", "Faculty/Staff"]);
        });

        return {
            submit,
            formRef,
            staffDetail,
            staffId,
            types,
            acceptPhoneNumber,
            addFileData,
            fileList,
            deleteSavedFile,
            downloadFile,
            moment,
            removeFileData,
            deleteStaff,
            backToList
        };
    },
});
